import styled from "styled-components";
import banner from './banner.png';
import { ReactComponent as ListItemTag } from "./listItem.svg";

export const StyledHeaderBar = styled.div`
  background-image: url(${banner});
  background-size: cover; 
  margin: 0;
  padding: 5px;
  min-height: 120px;
  height: auto;
  display: grid;
  grid-column: span 2;
  align-items: center;
    font-family: "Oswald", sans-serif;


  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    grid-column: span 1;

  }

    @media (max-width: ${({ theme }) => theme.breakPoints.mobileMax}) {
    min-height: 80px;
  }
`;

export const FirstPartHeader = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.white};

    @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    font-size: 20px;
  }
`;

export const SecondPartHeader = styled.div`
font-size: 30px;
  color: ${({ theme }) => theme.colors.pink};

    @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
     font-size: 20px;
  }
`;

export const ListItemContainer = styled.div`
margin: 1px 10px ;
display: flex;
`;


export const StyledListItemTag = styled(ListItemTag)`
  width: 23px;
`;


export const HeaderMainContent = styled.div`
  font-size: 17px;
  padding: 0 20px;
  text-align: center;
  align-self: center;
  color: ${({ theme }) => theme.colors.white};

    @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    font-size: 13px
  }
`;


export const Header = styled.h1`  
  display: flex;
  gap: 3px;
  margin: 10px;

  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    font-size: 13px;
  }
`;

export const FeaturesList = styled.ul`
  font-size: 13px;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.white};
  list-style: none;
  display: grid;
  grid-template-columns: auto auto 1fr;


  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    font-size: 11px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.mobileMax}) {
    display: none;
  }
`;

export const ListItem = styled.li`
padding: 5px;
`;

export const SpecificListItem = styled.li`
color: ${({ theme }) => theme.colors.pink};
padding: 10px 80px;
font-size: 18px;

@media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    grid-template-columns: auto 1fr;
    font-size: 9px;
  }

  `;
