export const checkDaysSincePublication = (createdTime) => {
    const currentDate = new Date().getTime();
    const publicationDate = new Date(createdTime).getTime();
    const quantityDays = 20;
    const referenceTime = quantityDays * 24 * 60 * 60 * 1000;
    const timeDifference = currentDate - publicationDate

    return (timeDifference < referenceTime);
};

