import { Tile } from "../../common/Tile";
import { getCurrentFeatures } from "../../utils/getApi";
import { useDataFetching } from "../../utils/useDataFetching";
import { compareObjects } from "../../utils/compareObjects";

export const CurrentFeatures = () => {
  const { data: currentFeatures } = useDataFetching(getCurrentFeatures);
  
  return !currentFeatures ? (
    ""
  ) : (
    <Tile list={currentFeatures.result.sort(compareObjects)} tileHeader={"Funkcjonalności Opcjonalne"} />
  );
};
