import styled from "styled-components";
import { ReactComponent as VectorDown } from "../../images/vectorDown.svg";
import { ReactComponent as VectorUp } from "../../images/vectorUp.svg";

export const StyledTile = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

export const TileHeader = styled.h2`
  font-size: 14px;
  font-weight: 500;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin: 0;
  padding: 8px;
  border-radius: 4px;
  background-image: linear-gradient(
    95deg,
    rgba(0, 0, 0, 1) 3%,
    rgba(45, 44, 44, 1) 86%
  );
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    font-size: 13px;
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-box-shadow: 1px 2px 24px -16px rgba(66, 68, 90, 1);
  -moz-box-shadow: 1px 2px 24px -16px rgba(66, 68, 90, 1);
  box-shadow: 1px 2px 24px -16px rgba(66, 68, 90, 1);
  overflow: scroll;
  overflow-x: hidden;
`;

export const ListItem = styled.li`
  font-size: 13px;
  display: grid;
  align-items: center;
  grid-gap: 5px;
  grid-template-columns: auto 1fr auto;
  margin-bottom: 10px;

  @media (max-width: ${({ theme }) => theme.breakPoints.mobileMax}) {
    font-size: 12px;
    grid-template-columns: auto 1fr;
  }
`;

export const NewConcept = styled.p`
 padding: 3px;
 border-radius: 3px;
 color: ${({ theme }) => theme.colors.white};
 font-size: 9px;
 margin: 0;
 -webkit-box-shadow: inset 0px 0px 20px 13px rgba(83, 82, 83, 1);
 -moz-box-shadow: inset 0px 0px 20px 13px rgba(83, 82, 83, 1);
 box-shadow: inset 0px 0px 20px 13px rgba(83, 82, 83, 1);

 @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
  font-size: 8px; 
}
`;


export const ListItemDescription = styled.div`
padding: 0;
margin: 0;
display: flex;
gap: 10px;
align-items: center;
`;

export const ItemDate = styled.span`
  font-size: 12px;
  justify-self: end;
  font-style: italic;
  color: ${({ theme }) => theme.colors.shadyLady};
`;

export const ExeVersion = styled.span`
  font-size: 12px;
  justify-self: end;
  font-style: italic;
  color: ${({ theme }) => theme.colors.shadyLady};
`;

export const ListItemContent = styled.p`
  text-align: justify;
  grid-column: span 3;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.shadyLady};
  margin: 5px;
`;

export const Link = styled.a`
  text-decoration: none;
  font-size: 12px;
  justify-self: end;
  color: ${({ theme }) => theme.colors.seance};

  &:hover {
    cursor: pointer;
    font-weight: 500;
    transition: 0.3s;
    color: ${({ theme }) => theme.colors.codGrey};
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    font-size: 11px;
  }
`;

export const StyledVectorDown = styled(VectorDown)`
  cursor: pointer;
  width: 10px;
`;

export const StyledVectorUp = styled(VectorUp)`
  cursor: pointer;
  width: 10px;
`;

export const DetailedItemInformation = styled.p`
display: flex;
gap: 15px;
align-items: center;
margin: 0;

@media (max-width: ${({ theme }) => theme.breakPoints.mobileMax}) {
  grid-column: span 2;
}
`;
