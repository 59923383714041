import React, { useState } from 'react';
import { MdVerified, MdScience, MdBuild } from 'react-icons/md';
import { ChangeLogsTile } from "./ChangeLogsTile";
import { TabsContainer, TabsHeader, TabItem, TabContent } from "./styled";
import { useDataFetching } from "../../utils/useDataFetching";
import { getChangeLogs } from "../../utils/getApi";

export const ChangesLog = () => {
  const { data: changeLogs } = useDataFetching(getChangeLogs);
  const [activeTab, setActiveTab] = useState("PROD");

  if (!changeLogs || changeLogs.length === 0) {
    return null;
  }

  const prodChangeLogs = changeLogs.filter(item => item.pamBuildVersion);
  const betaChangeLogs = changeLogs.filter(item => item.pamBuildVersionBeta);
  const devChangeLogs = changeLogs.filter(item => item.pamBuildVersionDev);


  const renderContent = () => {
    switch (activeTab) {
      case "PROD":
        return <ChangeLogsTile list={prodChangeLogs} tileHeader="Lista Zmian - wersja PROD" versionType="pamBuildVersion" />;
      case "BETA":
        return <ChangeLogsTile list={betaChangeLogs} tileHeader="Lista Zmian - wersja BETA" versionType="pamBuildVersionBeta" />;
      case "DEV":
        return <ChangeLogsTile list={devChangeLogs} tileHeader="Lista Zmian - wersja DEV" versionType="pamBuildVersionDev" />;
      default:
        return null;
    }
  };

  return (
    <TabsContainer>
      <TabsHeader>
        <TabItem active={activeTab === "PROD"} onClick={() => setActiveTab("PROD")}>
          < MdVerified /> PROD
        </TabItem>
        <TabItem active={activeTab === "BETA"} onClick={() => setActiveTab("BETA")}>
          <MdScience /> BETA
        </TabItem>
        <TabItem active={activeTab === "DEV"} onClick={() => setActiveTab("DEV")}>
          <MdBuild/> DEV
        </TabItem>
      </TabsHeader>
      <TabContent>
        {renderContent()}
      </TabContent>
    </TabsContainer>
  );
};
