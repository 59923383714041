import styled from "styled-components";

export const Section = styled.section`
  display: grid;
  padding: 15px;
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
  height: 97%;
  grid-gap: 10px;
  margin-top: 15px;

    @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
      grid-template-rows: auto;
    }
`;
