export const theme = {
    colors: {
      white: "#FFFFFF",
      alabaster: '#F7F7F7',
      whiteLilac: "#F4F1F9",
      shadyLady: "#929092",
      bonjour: "#EBEBEA",
      greyNickel: "#B3B3B2",
      seance: "#832697",
      codGrey: "#333333",
      pink: "#f40a99"
    },
    breakPoints: {
      tabletMax: "1000px",
      mobileMax: "767px",
    }
  };
  