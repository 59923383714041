import {
    StyledInterestLevelBar,
    FirstLevel,
    SecondLevel,
    ThirdLevel,
    FourthLevel
  } from "./styled";

  export const InterestLevelBar = ({ level }) => {

    const getInterestLevelComponent = (level) => {
        switch (level) {
          case "1/4":
            return <FirstLevel />;
          case "2/4":
            return <SecondLevel />;
          case "3/4":
            return <ThirdLevel />;
          case "4/4":
            return <FourthLevel />;
          default:
            return null;
        }
      };

    return ( 
    <StyledInterestLevelBar>
        {getInterestLevelComponent(level)}
    </StyledInterestLevelBar>
     )
  };