import React, { useState } from "react";
import {
  List,
  Link,
  ListItem,
  ListItemContent,
  ListItemDescription,
  StyledTile,
  StyledVectorDown,
  StyledVectorUp,
  TileHeader,
  NewConcept,
  DetailedItemInformation
} from "./styled";
import { InterestLevelBar } from "./InterestLevelBar";
import { checkDaysSincePublication } from "../../utils/checkDaysSincePublication";

export const Tile = ({ list, tileHeader }) => {
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    setOpenItem((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <StyledTile>
      <TileHeader>{tileHeader}</TileHeader>
      <List>
        {list.map((item, index) => (
          <ListItem key={item.id}>
            {openItem === index ? <StyledVectorUp onClick={() => toggleItem(index)} /> : <StyledVectorDown onClick={() => toggleItem(index)} />}
            <ListItemDescription>
              {item.title}
              {checkDaysSincePublication(item.createdDateFromTable) === true ? <NewConcept>Nowość  </NewConcept> : ''}
            </ListItemDescription>
            <DetailedItemInformation>
              {item.levelOfInterest ? <InterestLevelBar level={item.levelOfInterest} /> : ""}
              <Link href={item.link} target="_blank">Zobacz szczegóły</Link>
            </DetailedItemInformation>
            {openItem === index ? <ListItemContent dangerouslySetInnerHTML={{ __html: item.shortContent }}></ListItemContent> : ""}
          </ListItem>
        ))}
      </List>
    </StyledTile>
  )
};

