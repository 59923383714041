import {
  ChangeLogsList,
  Link,
  ChangeLogsListItem,
  ChangeLogsListItemDescription,
  StyledChangeLogsTile,
  ExeVersion,
  ChangeLogsTileHeader,
  ChangeLogsListItemRepoName,
} from "./styled";

export const ChangeLogsTile = ({ list, tileHeader, versionType }) => {

  const getRepoDisplayName = (repoName) => {
    if (repoName === "SQL") {
      return "PAM";
    } else if (repoName === "Reports") {
      return "RAPORT";
    } 
    return repoName.toUpperCase(); 
  };

  return (
    <StyledChangeLogsTile>
      <ChangeLogsTileHeader>{tileHeader}</ChangeLogsTileHeader>
      <ChangeLogsList>
        {list.map((item) => {
          const version = item[versionType];
          if (!item.description) return null;

          return (
            <ChangeLogsListItem key={item.id}>
              <ExeVersion>{`[${version}]`}</ExeVersion>
              <ChangeLogsListItemRepoName>
                {getRepoDisplayName(item.repoName)}
              </ChangeLogsListItemRepoName>
              {item.link ? (
                <Link href={item.link} target="_blank">Zobacz szczegóły</Link>
              ) : (
                <Link />
              )}
              <ChangeLogsListItemDescription>
                {item.description}
              </ChangeLogsListItemDescription>
            </ChangeLogsListItem>
          );
        })}
      </ChangeLogsList>
    </StyledChangeLogsTile>
  );
};
