import styled from "styled-components";

export const StyledChangeLogsTile = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
`;

export const ChangeLogsTileHeader = styled.h2`
  font-size: 14px;
  font-weight: 500;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin: 0;
  padding: 8px;
  border-radius: 4px;
  background-image: linear-gradient(
    95deg,
    rgba(0, 0, 0, 1) 3%,
    rgba(45, 44, 44, 1) 86%
  );
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    font-size: 13px;
  }
`;

export const ChangeLogsList = styled.ul`
  margin: 0;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-box-shadow: 1px 2px 24px -16px rgba(66, 68, 90, 1);
  -moz-box-shadow: 1px 2px 24px -16px rgba(66, 68, 90, 1);
  box-shadow: 1px 2px 24px -16px rgba(66, 68, 90, 1);
  overflow: scroll;
  overflow-x: hidden;
`;

export const  ChangeLogsListItem = styled.li`
  font-size: 12px;
  display: grid;
  align-items: center;
  grid-gap: 5px;
  margin-bottom: 10px;
  align-items: start;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto 1fr;
  line-height: 1.5;
  grid-gap: 8px;

  @media (max-width: ${({ theme }) => theme.breakPoints.mobileMax}) {
    font-size: 12px;
    grid-template-columns: auto 1fr;
  }
`;


export const ChangeLogsListItemDescription = styled.div`
padding: 0;
margin: 0;
display: grid;
gap: 10px;
align-items: start;
grid-column: span 3;
`;

export const ChangeLogsListItemRepoName = styled.div`
 box-shadow: inset 0px 0px 10px 0px rgba(185, 185, 186, 1);
 border-radius: 3px;
 font-size: 10px;
 margin: 0;
 padding: 0 5px 0;
 background-color: white;
 text-align: center;
 @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
  font-size: 8px; 
}
`;


export const ExeVersion = styled.span`
  font-size: 12px;
  justify-self: end;
  font-style: italic;
  justify-self: start;
  color: ${({ theme }) => theme.colors.shadyLady};
`;


export const Link = styled.a`
  text-decoration: none;
  font-size: 12px;
  justify-self: end;
  color: ${({ theme }) => theme.colors.seance};

  &:hover {
    cursor: pointer;
    font-weight: 500;
    transition: 0.3s;
    color: ${({ theme }) => theme.colors.codGrey};
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    font-size: 11px;
  }
`;
