import { checkDaysSincePublication } from "./checkDaysSincePublication";

export const compareObjects = (a, b) => {
    const isNewA = checkDaysSincePublication(a.createdDateFromTable);
    const isNewB = checkDaysSincePublication(b.createdDateFromTable);

    // Porównanie nowości
    if (isNewA && !isNewB) {
        return -1; // Obiekt A jest nowością, więc przechodzi przed obiektem B 
    }
    if (!isNewA && isNewB) {
        return 1; // Obiekt B jest nowością, więc przechodzi przed obiektem A 
    }


    // Jeśli daty są takie same, porównaj poziomy zainteresowania
    if (a.levelOfInterest > b.levelOfInterest) {
        return -1;
    }
    if (a.levelOfInterest < b.levelOfInterest) {
        return 1;
    }


    // Jeśli oba obiekty są nowościami lub oba nie są nowościami, porównujemy daty
    // Porównanie daty
    if (a.createdDateFromTable > b.createdDateFromTable) {
        return -1;
    }
    if (a.createdDateFromTable < b.createdDateFromTable) {
        return 1;
    }



    // Jeśli daty i poziomy zainteresowania są takie same, zachowaj oryginalną kolejność
    return 0;
};

