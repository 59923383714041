import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: grid;
  grid-template-rows: auto  minmax(0, 1fr);
  margin-left: 13px;
`;

export const TabsHeader = styled.div`
  display:flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 0 0 0;
 margin-bottom: 1px;
`;

export const TabItem = styled.button`
  display: flex;
  font-size: 15px;
  align-items: center;
  gap: 10px;
  padding: 2px 3px;
  cursor: pointer;
  background-color: #F7F7F7;
  border: none;
  border-bottom: ${(props) => (props.active ? '3px solid #f40a99' : 'none')};
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  color: ${(props) => (props.active ? '#f40a99' : '#333')};
  transition: 0.3s;
`;

export const TabContent = styled.div`
  padding: 0 20px 20px 0px;
  border-radius: 0 0 5px 5px;
`;
