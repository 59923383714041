import styled from "styled-components";

export const StyledInterestLevelBar= styled.div`
  width: 110px; 
  height: 11px; 
  border-radius: 5px; 
  overflow: hidden; 
  -webkit-box-shadow: inset 0px 0px 10px 0px rgba(185, 185, 186, 1);
  -moz-box-shadow: inset 0px 0px 10px 0px rgba(185, 185, 186, 1);
  box-shadow: inset 0px 0px 10px 0px rgba(185, 185, 186, 1);

  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    width: 90px; 
  }
`;

export const Level = styled.div`
  height: 100%; 
  background-color: ${({ theme }) => theme.colors.white};
  float: left; 
  -webkit-box-shadow: inset 0px 0px 25px -6px rgba(190, 45, 190, 1);
  -moz-box-shadow: inset 0px 0px 25px -6px rgba(190, 45, 190, 1);
  box-shadow: inset 0px 0px 25px -6px rgba(190, 45, 190, 1);
`;

export const FirstLevel = styled(Level)`
  width: 25%; 
`;

export const SecondLevel = styled(Level)`
  width: 50%; 
`;

export const ThirdLevel = styled(Level)`
  width: 75%; 
`;

export const FourthLevel = styled(Level)`
  width: 100%; 
`;
