import { StyledHeaderBar, Header, FeaturesList, FirstPartHeader, SecondPartHeader, HeaderMainContent, ListItem, SpecificListItem, ListItemContainer, StyledListItemTag } from "./styled";

export const HeaderBar = () => (
  <StyledHeaderBar>
    <Header>
      <FirstPartHeader>
        PAM
      </FirstPartHeader>
      <SecondPartHeader>
        MOBILE
      </SecondPartHeader>
      <HeaderMainContent>
        Zautomatyzuj logistykę, zarządzaj dostawami, zwiększ efektywność!
      </HeaderMainContent>
    </Header>
    <FeaturesList>
      <ListItemContainer>
        <StyledListItemTag />
        <ListItem>
          Monitorowanie dostaw w czasie rzeczywistym
        </ListItem>
      </ListItemContainer>

      <ListItemContainer>
        <StyledListItemTag />
        <ListItem>
          Możliwość natychmiastowej reakcji na zmiany
        </ListItem>
      </ListItemContainer>

      <ListItemContainer>
        <StyledListItemTag />
        <ListItem>
          Pełna kontrola nad ilością elementów do spakowania
        </ListItem>
      </ListItemContainer>

      <ListItemContainer>
        <StyledListItemTag />
        <ListItem>
          Precyzyjnie wskazanie miejsc dostaw
        </ListItem>
      </ListItemContainer>

      <ListItemContainer>
        <StyledListItemTag />
        <ListItem>
          Dostępny na iOS oraz Android
        </ListItem>
      </ListItemContainer>

      <SpecificListItem >
        Już wkrótce w naszej ofercie...
      </SpecificListItem >

    </FeaturesList>
  </StyledHeaderBar>
);


/* <StyledHeaderBar>
    <Header>Rozwijamy się dla Ciebie!</Header>
    <Span>
      Bądź na bieżąco z wprowadzonymi zmianami, nowymi koncepcjami oraz poznaj
      wszystkie funkcjonalności aplikacji, aby w pełni korzystać z jej
      możliwości.
    </Span>
  </StyledHeaderBar>


  export const StyledHeaderBar = styled.div`
  margin: 0;
  padding: 10px;
  height: auto;
  display: grid;
  grid-column: span 2;
  background-image: linear-gradient(
    95deg,
    rgba(0, 0, 0, 1) 3%,
    rgba(45, 44, 44, 1) 86%
  );

  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    grid-column: span 1
  }

  export const Header = styled.h1`
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    font-size: 13px;
  }
`;

export const Span = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    font-size: 13px;
    line-height: 1.5
  }
`;

`;
  */ 